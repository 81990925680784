import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './FAQ.css';

const FAQ = () => {

    const faqs = [
        {
            id: '1', 
            question: 'What are the goals (5) of \'Cathy\'s Computer\'?', 
            answer: (
                <>
                1) <Link to="/payment">Pay</Link> rent online to increase your credit score, never write another check or miss a payment, pay in pieces or advance, and save time + gas!<br/>
                2) Send <Link to="/notice">notices</Link> electronically for broader and modern access. Keep them in your inbox vs on your table and get them when you aren't home.<br/>
                3) Provide better <Link to="/maintenance">maintenance</Link> issue delivery for faster and more consistent service with records.<br/>
                4) Assist people in selling / buying a <Link to="/contract">contract</Link>, as well as understanding it.<br/>
                5) Give tenants the records and <Link to="/resource">resources</Link> they need under the management.
                </>
            )
        },  
        {
            id: '2',
            question: 'How do rent and parking work?', 
            answer: (
                <>
                1) You can <Link to="/payment">pay</Link> the rent (R) + internet, parking (P), and late (L) costs online, which includes the fees we have to pay for online transactions. <br/>
                2) We pay Cathy at the end of the month as funds finalize for you, and <br/>
                3) you get her receipt by email or in person for your payment. Update if you pay for parking via <Link to="/profile">Profile</Link>. <br/>
                See <Link to="/termsAndConditions">Terms and Conditions</Link> for more info and how to reduce the fees!
                </>
            )
        },
        {
            id: '3',
            question: 'Why is rent late on the 26th?', 
            answer: (
                <>
                Processing can take up to 7 business days, so we need to take into account weekends, holidays, and 
                short months. We recommend paying with recurring payments, which will bill on the same day and you can 
                always cancel.
                </>
            )
        },
        {
            id: '4',
            question: 'What is \'Buy us an aspirin\'?', 
            answer: (
                <>
                This is an 50&#162; option you have at payment to support the developers. Click "Details" 
                during payment to increase or decrease it. Card processing fees, server costs, printing pages, envelopes, 
                gas, and more are all subsidized by us personally and your appreciated donations. If you like 
                what we've done, help us help you! We are all college students - pay what you want or not at all
                </>
            )
        },
        {
            id: '5',
            question: 'How does maintenance work?', 
            answer: (
                <>
                1) You <Link to="/maintenance">submit</Link> a request, <br/>
                2) we print it off and give it to Cathy in writing [see BYU contract 7.1.F], and <br/>
                3) you get a picture of the written notice. In our experience, this 
                helps it get done!
                </>
            )
        },
        {
            id: '6',
            question: 'How do notices work?', 
            answer: (
                <>
                1) Cathy [or a tenant] alerts us about a <Link to="/notice">notice</Link> for a building, <br/>
                2) we send it to the residents of the building via email and <br/>
                3) post the transcription on the website for respective tenants. Yes, the one about our flyer is on there. <Link to="/support">Contact</Link> us to learn more!!
                </>
            )
        },
        {
            id: '7',
            question: 'How do contracts work?', 
            answer: (
                <>
                1) You notify us you are selling your contract via the <Link to="/profile">Profile</Link> page, <br/>
                2) we post your contact info for others to see so you can connect, <br/>
                3) once you find a buyer, you both go to Cathy and sign in person. The <Link to="/contract">contracts</Link> page 
                also contains a copy of your contract.
                </>
            )
        },
        {
            id: '8',
            question: 'Terms and Conditions?', 
            answer: (
                <>
                <Link to="/termsAndConditions">Here's</Link> a link, please read them.
                </>
            )
        },
        {
            id: '9',
            question: 'What\'s the motivation?',
            answer: (
                <>
                1) Because online presence is exceptionally convenient for tenants and a standard expectation for landlords. <br/>
                2) It's another project to add to the profiles of our developers, and <br/>
                3) this is our solution to problems we see and is considered by us a community contribution.
                </>
            )
        }
    ];

    const [expandedFaq, setExpandedFaq] = useState(null); 

    const toggleFaq = (index) => {
        setExpandedFaq(expandedFaq === index ? null : index);
    };

    return (
        <div>
        <div className='faq-container'>
            <h2 className='about-title'>About / FAQ</h2>
            <p className='about-body'>Cathy is awesome! She also doesn't even have an email. "Cathy's Computer" was 
                named after our favorite typewriter, and is run by her renters for her renters. 
                We've all seen how an online system would be a dream - we took it upon ourselves as software developers 
                to make it a reality. Our mission is to be the bridge between Cathy's paper and your pixels. Login to 
                see the most recent notice she posted! Yes, that one haha.</p>
        </div>
        <ul className="my-accordion">
            {faqs.map((faq, index) => (
                <li key={index} className="my-accordion-item">
                    <div className="my-accordion-title" onClick={() => toggleFaq(index)}>{faq.question}</div>
                    {expandedFaq === index && (
                        <div className="my-accordion-content">
                            <p>{faq.answer}</p>
                        </div>
                    )}
                </li>
            ))}
        </ul>
        </div>
    );
};

export default FAQ;
